import { fetchUSer } from "../../../../Core/fetchLocalStoreData";



const ImprimirEntradaPatio = ({documento }) => {

    const user = fetchUSer();
    if (!documento) {
        return false;
    }


    return (
        <div id=''>
            <div className="TirillaTextCenter">     
                <h4 className="TirillaM0">{user.ESTACION.nombre}</h4>
                <b className="Tirilla11px"> NIT : {user.ESTACION.nit}</b>
                <p style={{marginTop:'-2px'}}>
                    {user.ESTACION.telefono} <br/> 
                    {user.ESTACION.direccion}<br/>
                    {user.ESTACION.municipio} <br/>
                </p>
            </div>

            <h3>PLACAS  #{documento.placa}</h3>
            Descripcion : {documento.OBJETO.nombre}<br/>
            Categoria:  {documento.OBJETO.nombreCategoria}
            <table>
                <tr>
                    <th>Entrada: </th>
                    <td> {documento.patio_entrada_fecha}  </td>
                </tr>
                <tr>
                    <th> Recibido por:  </th>
                    <td> {documento.FORM.nombreCajero}  </td>
                </tr>
            </table>
     
        </div>
    );
};

export default ImprimirEntradaPatio;
