import Estandar_12h from "./Estandar_12h";
import Estandar_24h from "./Estandar_24h";
// Importa otros algoritmos aquí







// Crear un mapa de algoritmos disponibles
const algoritmosDisponibles = {
    Estandar_12h: Estandar_12h,
    Estandar_24h: Estandar_24h,
    // Agrega aquí más algoritmos si es necesario
};

export const Renderizar = ({ fecha1, fecha2 , algoritmo, tarifasbd  }) => {

    // Verifica si el algoritmo existe en el mapa
    const AlgoritmoSeleccionado = algoritmosDisponibles[algoritmo];

    if (!AlgoritmoSeleccionado) {
        // Si el algoritmo no existe, arrojar un error o manejarlo
        throw new Error(`El algoritmo ${algoritmo} no está disponible.`);
    }


    // Instanciar la clase dinámica con la tarifa u otros parámetros
    const instanciaAlgoritmo = new AlgoritmoSeleccionado({
        fecha1,
        fecha2,
        tarifasbd
    });

    
  
   
    let    data =  {
            
            TIEMPO_EN_BLOQUE : instanciaAlgoritmo.TIEMPO_EN_BLOQUE,
            TIEMPO : instanciaAlgoritmo.TIEMPO,
            log: instanciaAlgoritmo.log,
            explicacion : instanciaAlgoritmo.explicacion,
            TOTALES : instanciaAlgoritmo.COBROS
        };
    
    return data;

};


export const  getAlgoritmosDisponibles = () => {

    return Object.keys(algoritmosDisponibles)
}

