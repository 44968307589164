
import prindiv from 'easyprintand';
import { __app, __fecha } from '../../../utils/myApp';
import { useState } from 'react';

const ImprimirPatio = ({ documentos}) => {
    

    const [fecha_impresion , Setfecha_impresion ] = useState();
    
    const handlePrint = () => {
        Setfecha_impresion(__app.getFecha());
       setTimeout(() =>  prindiv('EasyPrintAndPatio')  , 300 )
    }

    
    if (documentos === null) {
        return null; // Cambié 'false' por 'null' para que no renderice nada
    }


    return (

      <div className=''>
          <button onClick={() =>handlePrint()} >Imprimir Patio</button>
             <div id=''>

           

            <div className='ocultar' id='EasyPrintAndPatio'>
                <h3>Vehiculos en Patio</h3>
                <p>Hora de Impresion : {__fecha.formatoFecha({ $fecha: fecha_impresion , $icon : false }) } </p>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Entrada</th>
                            <th>Placa</th>
                            <th>Categoria</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {documentos.sort((a, b) => b.fechaidFinal - a.fechaidFinal).map((docu, index) => (
                            <tr key={index}>
                                <td>{__fecha.formatoFecha({ $fecha: docu.patio_entrada_fecha , $icon : false })}</td>
                                <td>{docu.placa}</td>
                                <td>{docu.OBJETO.nombreCategoria}</td> 
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
                    
       </div>
      </div>
    );
};



export default ImprimirPatio;