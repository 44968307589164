import { nanoid } from 'nanoid'

import { __app } from '../../utils/myApp';

import {enviarPeticion} from "../../Core/ActionDatabase";

import {  remove, ref , query, set,getDatabase } from "firebase/database";

import { recentOpenFacRef , getNit} from "../../Core/FireBase";

import { getUltimoRegistro, reloadDatabases } from '../../Core/Dixie';

import { NotificationManager} from 'react-notifications';
import { insertarCambioFirebase } from '../Productos/Controlador';
import { useState } from 'react';
import { fetchUSer } from '../../Core/fetchLocalStoreData';





export const initialstateDocument = {

    /*** Haciendo  */
    TIPO_DOCUMENTO : null,

    /** Completado */
    TERCERO : null,


    /** En proceso  */
    FORMAS_DE_PAGO : null,


    /** Completado */
    PRODUCTOS  : [
    ],


    /*** Completado */
    MATEMATICAS : {
    
    },

    
    
    NUMERACION : null,



    FORM : null,



    OBJETO : null,

    ESTRAS : {

        PAGA_CON : {
            
        },

        /*** Esta variable esm para deteminar que precio se esta usando en el documento
         * 
         *  Podemos establecer una lista de precio por defecto sin nesecidad de un cliente ni de usar un select,
         *  Ejemplo traerlo configurado desde a BASE de datos por punto de venta
         * 
         *  Si es 0 entonces se tomara el valor Original del producto
         */
        SET_DEFAULT_PRECIO : 3,


        LISTA_PRECIO_ACTIVA : 0,

        PORCENTAJE_GLOBAL : 0,

        OBLIGAR_DESCUENTO_GLOBAL : 0,
        
    },

    KEYDOCUMENT : null

}

export const SetinitialStateDocumento = () => {
   
    let user = fetchUSer();

    let lista_precio = 0;

    if (user.ESTACION && user.ESTACION.ct_lista_precio_id && user.ESTACION.ct_lista_precio_id.length > 0) {
        // Intentamos convertir la cadena a un entero
        const aux = parseInt(user.ESTACION.ct_lista_precio_id, 10);

        // Verificamos si el resultado es un número y no es NaN
        if (!isNaN(aux)) {
            lista_precio = aux;
        }
    }

    
    return {
        ...initialstateDocument,
        ESTRAS: {
            ...initialstateDocument.ESTRAS,
            SET_DEFAULT_PRECIO: lista_precio
        }
    }
}


export const getLocalStorageDocument = (key) => {
    let  data  = localStorage.getItem(key);
    if ( data !== undefined || data != null){
        data =  JSON.parse(data);
        return data;
    }
    return null;
}


export const getExtraInitial = (user = null) => {

    let auxFecha = __app.getFecha();

    let initial = {

        id :  nanoid(10),
        
        fechaCreacion :  auxFecha,

        /*** Otro Id por si se nesecita. talvez para controlar los items, osea, Que item se agrego en ese ID. O una Serie */
        fechaid :      __app.numeroFecha(auxFecha),

        /** 
         *  1 Si la Factura se encuentra Sincronizada Correctamente
         */
        EstadoServidor : 0,

        /**
         * 1 Si la Factura se encuentra Cerrada. 0 si esta abierta y se puede seguir Modificando
        */
       EstadoLocalFactura : 0,


       turno_id: user?.TURNO?.id || 0,  // Valida si user, TURNO y id existen
    }

    return initial;
}



export const resetearDocumentoGlobal = (tab , dispatch , actionTypeDocument , TIPO_DOCUMENTO) =>  {

    console.log(tab );

    let initial = getExtraInitial();
    const aux = SetinitialStateDocumento();
    const copiaDC  = {...aux , ...initial}
    localStorage.setItem(tab.keyLocalStogere , JSON.stringify(copiaDC) );

    console.log(copiaDC)
    console.log(copiaDC)
    console.log(copiaDC)
    console.log(copiaDC)
    console.log(copiaDC)
    dispatch({
        type : actionTypeDocument.SET_DOCUMENTO,
        documento:  {...copiaDC , KEYDOCUMENT : tab  , TIPO_DOCUMENTO : TIPO_DOCUMENTO},
    })
}



/**** Esta funcion generera un documento fantasma en blanco  */
export const generarDocumentoFantasma = (TIPO_DOCUMENTO , user ) =>  {


    let initial = getExtraInitial(user);
    const aux = SetinitialStateDocumento();
    const copiaDC  = {...aux , ...initial}
    const copiDC2 = {...copiaDC , TIPO_DOCUMENTO : TIPO_DOCUMENTO}

    return copiDC2
}


export const eliminarCuentaAbiertaFireBase = ($id , user ) => {


    const db = getDatabase();
    const listener = ref(db, 'cambiosdatabase/'+getNit()+'/cuentas_abiertas/punto_venta_'+ user.ESTACION.id + '/'  + $id) 
    remove(listener).then(() => console.log("Deleted"))

}


export const  eliminarCuentaAbiertaDB = async (cuenta,user) => {

    let resultado  =  await enviarPeticion("adddocumentos/eliminar_cuenta_abierta_fire_base"  , user.ESTACION.id + "/" + user.TURNO.id  + "/" + user.USER.id   ,  
    {
        body : cuenta ,
    }  
    );

    return resultado;
    
}


export const  cargarInventarioItems = async (items ) => {

    let estacion_id = 1;

    let resultado  =  await enviarPeticion("inventarios/info_inventario_items" , null , {
        body : items
    });

   
    if( resultado && resultado.respuesta &&  resultado.respuesta == 1 ){

        return resultado.data;
      
    }else{

        return  null;

    }

}






export const guardarHistoriasItemBorradosCuentaAbierta = async (item, documento , user  ) => {

    let copyItem =  JSON.parse( JSON.stringify(item) );
    copyItem.fecha_eliminacion = __app.getFecha();

    let resultado  =  await enviarPeticion("adddocumentos/eliminar_item_cuenta_abierta"  , user.ESTACION.id + "/" + user.TURNO.id  + "/" + user.USER.id   ,  
        {
            body : {
                item : copyItem,
                user_id : user.USER.id,
                estacione_id : user.ESTACION.id,
                turno_id : user.TURNO.id,
                documento_id :  documento.id
            } ,
        }  
    );

    return resultado;
}


    /**
     * 
    */
    export const guardarDocumentoLocal = (copy)  => {

        const user = fetchUSer();
      
        /** Cerramos la factura para indicar que  ya no se puede modificar */
        let copy2 = { ...copy , 
            turno_id : 
            user.TURNO.id,  
            EstadoLocalFactura: 1 ,
            fechaFinal : __app.getFecha() , 
            fechaidFinal:  __app.numeroFecha(__app.getFecha())
        } ;
        
        let documentoOBj  = [copy2];
        console.log(copy2);
        reloadDatabases(documentoOBj     , "documentos");
        NotificationManager.success('Documento Guardado con Exito ', 'Guardado'); 
        return copy2;
    }

    export const listaNegra = (recargar = true) => {
      
        var nit = getNit();
    
        if(  true ){

            let  listdocument = localStorage.getItem('listdocument');


            if( listdocument ){
                listdocument =  JSON.parse(listdocument);   
            }

            listdocument.forEach( (data) => {
                localStorage.removeItem(data.keyLocalStogere);
            } )



            localStorage.removeItem("listdocument");
            localStorage.removeItem("documente_0");
            localStorage.removeItem("documente_1");
            localStorage.removeItem("documente_2");
            localStorage.removeItem("documente_3");
            localStorage.removeItem("documente_4");
            localStorage.removeItem("documente_5");
            localStorage.removeItem("activeTab");


            deleteDB("data");

            // Limpiar localStorage
            localStorage.clear();

            // Limpiar sessionStorage
            sessionStorage.clear();
                      

            if(recargar){
                window.location.href = 'https://www.nubep7.com';
            }else{
                window.location.href = 'http://localhost:3000/';
            }
           
                
        }

    }

    const deleteDB = (database) => {

        var nit = getNit();

        if(true){
            // Eliminar IndexedDB
            var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
            var request = indexedDB.deleteDatabase(nit);
        
            request.onsuccess = function () {
                console.log("IndexedDB eliminado con éxito");
            };
        
            request.onerror = function (event) {
                console.log("Error al eliminar IndexedDB: " + event.target.errorCode);
            };

            
        }
   
    }

    export const actualizarProductos = (data) => {
        console.log()
        if(data.versiondatabase){
            insertarCambioFirebase(data.versiondatabase.aux);
        }
    }



    export const extraerUltimoDocumentoByCache = async () =>  {

        return await  getUltimoRegistro('documentos' , 'fechaidFinal')

    }


    export const  getTipoDocumentoId = () => {
        
    }