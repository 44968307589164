import Tiempos from "./Tiempos";

export default class Estandar_12h extends Tiempos {

    /***
     * Descripción del Cobro
     * 
     * Este cobro inicia cobrando por hora.
     * Cuando el valor de las horas supera el valor de 12 horas,
     * olvida el cobro por hora y aplica un valor fijo por 12 horas.
     * 
     */
    constructor({fecha1,fecha2,tarifasbd}) {
        // Llama al constructor de la clase padre (Tiempos)
        super();


        this.explicacion = `
            <p>Este algoritmo inicia cobrando por hora.</p>
            <p>Una vez el valor total de las horas supere el costo diario, se dejará de acumular el cobro por hora y se cobrará el costo del día.</p>
            
            <p>Las variables utilizadas son las siguientes:</p>
        
            <ul>
                <li><strong>aux1:</strong> Es el valor por cada 12 horas.</li>
                <li><strong>aux2:</strong> Valor por hora.</li>
                <li><strong>aux3:</strong> Tarifa mínima por entrar y sacar el vehículo.</li>
                <li><strong>aux4:</strong> Valor por cuarto de hora.</li>
                <li><strong>aux5:</strong> Es un tiempo. Escriba aquí después de cuántos minutos el sistema debe contarlo como una hora. 
                    <br>Ejemplo: Si quieres que después del minuto 10 el sistema cobre una hora completa, escribe el número 10.</li>
            </ul>
        `;



        this.fecha1 = fecha1;

        this.fecha2 = fecha2;

        // Definir las tarifas
        this.TARIFAS = {

            //aux1
            VALOR_12HORAS: tarifasbd.aux1,
            
            //aux2
            VALOR_HORA: tarifasbd.aux2,
            
            //aux3
            VALOR_MINIMO: tarifasbd.aux3,
            
            //aux4 
            VALOR_CUARTO_DE_HORA: tarifasbd.aux4,
        };

        // Opciones relacionadas con el tiempo
        this.OPCIONES_DE_TIEMPO = {
            /*** Se evalúa esta variable para transformar los minutos en una hora adicional */
            TRANSFORMAR_HORA_DESPUES_DE_X_MINUTOS: tarifasbd.aux5,
        };

        // Valores de cobros inicializados en 0
        this.COBROS = {
            VALOR_12H: 0,
            VALOR_X_HORAS: 0,
            VALOR_X_MINUTOS: 0,
            VALOR_A_PAGAR : 0
        };

        this.renderizar();


    }

    renderizar ( ){

        console.log(this);
    
        this.addLog("Algoritmo iniciado: Estandar_12h"  )
        
        this.index(this.fecha1 , this.fecha2);

        this.IniciarCobro();

    }

    IniciarCobro(){


        //Calculando 12horas
        this.COBROS.VALOR_12H = (  this.TIEMPO.dias_y_noches_12   ) * this.TARIFAS.VALOR_12HORAS;



        /// Si los minutos superan los minutos de gabela, entonces cobramos una hora completa por esos minutos
        if( this.TIEMPO.minutos_sobrantes  >   this.OPCIONES_DE_TIEMPO.TRANSFORMAR_HORA_DESPUES_DE_X_MINUTOS ){

            this.COBROS.VALOR_X_MINUTOS =  this.TARIFAS.VALOR_HORA;
        }else{

            //En caso de que no se cumpa la condicion anteriro cobramos esos minutos como cuarto de hora.
            this.COBROS.VALOR_X_MINUTOS =  this.TARIFAS.VALOR_CUARTO_DE_HORA;
        }




        
        //cobramos las horas.
        this.COBROS.VALOR_X_HORAS =   this.TIEMPO.horas_sobrante_by12H *    this.TARIFAS.VALOR_HORA;




        //Si la suma de las horas y munutos es Mayor a la Tarifa por dia, entonces ignoramos ese valor y le cobramos las horas al valor del dia
        if( (this.COBROS.VALOR_X_MINUTOS + this.COBROS.VALOR_X_HORAS ) >=     this.TARIFAS.VALOR_12HORAS   ){

            this.COBROS.VALOR_X_MINUTOS = 0;
            this.COBROS.VALOR_X_HORAS =   this.TARIFAS.VALOR_12HORAS;
        }


        this.COBROS.VALOR_A_PAGAR =  parseFloat(this.COBROS.VALOR_12H) +  parseFloat(this.COBROS.VALOR_X_HORAS) +  parseFloat(this.COBROS.VALOR_X_MINUTOS);
            
        
       

    }

}