import React from 'react';
import { useEffect , useRef, useState } from 'react';
import { __app } from '../../../../utils/myApp';


const  FormaPagoPaso1 = ({documento,tab , cambiarPaso  , cambiarEstado , pagoDocumento , actualizartotalpagado, user}) => {


    
    
    let initialPropina = (documento.MATEMATICAS.PROPINA) ? documento.MATEMATICAS.PROPINA : 0;

    const [ propina , setPropina ] = useState(initialPropina);


    const [ totalRealPagar , SettotalRealPagar ] = useState(  (documento.MATEMATICAS.SUBTOTAL_3 + propina) -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR   )



    const [lisTipoCreditos , SetlisTipoCreditos ] = useState(user.DATABASE.tipo_creditos);


    const formaterPago = (forma_de_pagos) => {
       
        let result = forma_de_pagos.map( (fp) => {   
            
            if( parseInt(fp.tipo_de_banco_id) === 1 ){
            
                fp.total =  (documento.MATEMATICAS.SUBTOTAL_3 + propina) -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR;
            }else{
                fp.total = 0;
            }
            fp.banco_id =  ConsultarBancoCajaxDefecto(fp);
            return fp;
        })
        return result;
    } 




   
    const [ formpagos , setFormaPagos ] = useState(formaterPago(user.FORMAS_DE_PAGO));
    


    const [ pagacon , setPagacon ] = useState("")




    const [ totalpagado , setTotalpagado ] = useState( documento.MATEMATICAS.SUBTOTAL_3 + propina  );
    

    const [ totalCredito , settotalCredito ] = useState();



    /*** Esta variable muestra las obciones de pago en caso de que el documento tenga pagos.
     * 
     *  Hay documentos que no tienen pagos: cotizaciones y entre otros.
     *  Hay cortesia.
     * Y tambien saldo a favor que anulan.
     */
    const [ mostrarObcPagos , setmostrarObcPagos ] = useState(1);

    
    const [ tipoCredito , SetTipoCredito ] = useState(1);

    /*** En este Constructor vamos a actualizar las forma de pago en caso tal de que ya esten establecidas */
    useEffect( () => {
        
        if (documento.FORMAS_DE_PAGO ){
           
        
            /***  Con esto nos Aseguramos que el total se actualize cuando se agrege un nuevo producto  */
            if (  documento.ESTRAS.FORMA_PAGO_TOTAL_ACTUAL ==  documento.MATEMATICAS.SUBTOTAL_3  ){              
             
                setFormaPagos(documento.FORMAS_DE_PAGO);
                getTotalPagado(documento.FORMAS_DE_PAGO);
            }
        }


        
        /*** Asignando los bancos alas cajas */
        if (user.TURNO.caja_id){
            console.log(formpagos);
            let copi = [...formpagos];
            copi[0] = {...copi[0], banco_id : user.TURNO.caja_id };
            setFormaPagos(copi);
        }

        /** Consultar si hay pago */

        if ( (documento.MATEMATICAS.SUBTOTAL_3  + propina ) -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR  <=  0  ) {

            setmostrarObcPagos(0);
            setFormaPagos([]);
        }


        if( documento.TIPO_DOCUMENTO.signo_pago == 0 ){

          
            cambiarPaso(2); 
            pagoDocumento([] , 0 , 0 , 0);


        }


    } , [])



    const handleInputChange = (event) => {   
    
        let index  = parseInt(event.target.getAttribute("data-index"));
        let name   = event.target.getAttribute("data-name");
        let value = event.target.value;
        console.log("DIPARADOOO!!!")
        setPagoLvlDos(index,name, value)
    }

    const setPagoLvlDos = (index, name, aux ) => {   


        console.log(  formpagos[index] );


        let copi = [...formpagos];
        
        /*** Aqui eliminamos letras */
        aux = aux.length  == 0  ? 0 : aux; 
        
        aux   =  isNaN(aux) ? 0 : parseFloat(aux) ; 
        /* Manera Obsoleta de modificar javascrpit : 
        copi[ index ][ name ] = 10;*/
        
        copi[index] = {...copi[index], [name]: aux};


        console.log(  copi[index] );
        console.log(  copi[index] );
        console.log(  copi[index] );

        setFormaPagos(copi); 
        
        getTotalPagado(copi);
    }

    const setPagoAutomatico = (index, name, total) => {
        
        let indexx = formpagos.findIndex((pago) => pago.total >  0 )
        console.log(indexx)

        if(indexx ==index ) {
            
            handleGuardarPago();
            return false;
        }

        let copi = formpagos.map( (pago) => {
            pago.total = 0;
            return pago;
        } )
        setFormaPagos(copi); 
        setPagoLvlDos(index, name, total);
           
        // handleGuardarPago();
    }

    const enviarCreditodirecto = () => {

        let copi = formpagos.map( (pago) => {
            pago.total = 0;
            return pago;
        } )
        setFormaPagos(copi);
        getTotalPagado(copi);
        handleGuardarPago();
    }


    const getTotalPagado  = (copi) => {

        let total = copi.reduce(function(sum, current) {
            return sum + current.total;
        }, 0); // 43 + 32 + 21 = 96
        setTotalpagado(total);
        actualizartotalpagado(total);
    }

    const handlePagacon = (event) => {
        setPagacon(Number(event.target.value));
    }
    
    
    const inputEl = useRef(null);
    
    useEffect( () => {
        setTimeout(() => {
            inputEl.current.focus();
          }, 300);
    } , [] )



    useEffect( () => {
        let total = documento.MATEMATICAS.SUBTOTAL_3 - totalpagado;

        let estado = (total > 0 ) ? 0 : 1 ;
        cambiarEstado(estado);


        settotalCredito( ((documento.MATEMATICAS.SUBTOTAL_3 + propina)  -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR   ) - totalpagado );

    }  , [totalpagado , documento.MATEMATICAS , propina])




    const handleGuardarPago = () => {
        getTotalPagado(formpagos);
        cambiarPaso(2); 
        pagoDocumento(formpagos , pagacon , propina , tipoCredito);
    }


    if(formpagos.length  === 0 && mostrarObcPagos ===  1 ){
        return (
            <h1> No hay formas de pagos para cargar </h1>
        );
    }



    const DispararkeyPress = (e) => {
        

        if(e.key === 'Enter'){
            handleGuardarPago();
        } 
    }


    const handlePropina = (e) => {

        let copi = formpagos.map( (pago) => {
            pago.total = 0;
            return pago;
        } )
        setFormaPagos(copi);
        setPropina(   documento.MATEMATICAS.SUBTOTAL_3   *   e.target.value);
        setPagoLvlDos( 0 , 'total' , documento.MATEMATICAS.SUBTOTAL_3 + ( documento.MATEMATICAS.SUBTOTAL_3 *   e.target.value ));

    }


    function ConsultarBancoCajaxDefecto(formaPago){

        if(!user.BANCOS_ESTACIONES){
            return 0;
        }
        let infoBancoDefault = user.BANCOS_ESTACIONES.filter(( banco, index) => banco.forma_pago_id ==  formaPago.id );
        if(infoBancoDefault.length > 0 ){
            return infoBancoDefault[0].banco_id;
        }
        return formaPago.banco_id;

    }




    return (
        <div className='d-flex justify-content-center'> 
        
      

        <div className='row'>   
            
            { mostrarObcPagos == 1 && <div className="d-flex flex-row-reverse bd-highlight">
                {user.CONFIGURACIONES_TABLE.estado_aplicacion == 2 && <button onClick={ () => console.log(documento) } > Log pagos </button> }
                <button onClick={enviarCreditodirecto} className='p-2 mb-3 bd-highlight btn-lg btn btn-subtle-warning '> <i class="fa-regular fa-credit-card"></i> Enviar como  Credito </button>
            </div>}


            { user.CONFIGURACIONES_TABLE.propinas == 1 &&  <div>    
                Propina<select   onChange={handlePropina} >
                    <option value={0}>0%</option>
                    <option value={0.05}>5%</option>
                    <option value={0.10}>10%</option>
                </select>
            </div>}

            <div className='col-5'>

                
                <p className='fs-5'>Total Factura:   { __app.formato_decimal({total :  documento.MATEMATICAS.SUBTOTAL_3}) } </p>
           
                { documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0  && <p>Saldo a Favor :  { __app.formato_decimal({total :  documento.MATEMATICAS.TOTAL_SALDO_FAVOR}) } </p> }

                { user.CONFIGURACIONES_TABLE.propinas == 1 && <p className='fs-2'>Propina:   {  propina } </p> }
            
                { user.CONFIGURACIONES_TABLE.propinas == 1 || (  documento.MATEMATICAS.TOTAL_SALDO_FAVOR &&   documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0 )    && 
            
            
                <span>
                    {  (documento.MATEMATICAS.SUBTOTAL_3  + propina ) -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0 && 
                    
                    <span> 
                        <p className='fs-2'>Total a Pagar:   { __app.formato_decimal( {total :  (documento.MATEMATICAS.SUBTOTAL_3  + propina ) -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR}) } </p>
                    </span> 
                    
                    ||

                    <span> 
                        <span> TOTAL A PAGAR : $ 0 </span>


                        <p  className='badge bg-success' >Nuevo saldo a Favor de  : { ( (documento.MATEMATICAS.SUBTOTAL_3  + propina ) -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR) * -1  }   </p>
                        
                    </span>
                    }
                </span>
            
            }





                { mostrarObcPagos == 1 && <div>

                    <div className='form-group'>
                        <label htmlFor="">Paga Con</label>
                        <input autoComplete='off' onKeyUpCapture={DispararkeyPress}  ref={inputEl}  onChange={handlePagacon} value={pagacon} name="pagacon" style={ {"height":" 150px" }} placeholder="$" className="fs-3 form-control text-center" />
                    </div>

                    <div>    
                        <p className='p-2  text-center'>  <span  className=' fs-6 text-center '>Devuelves </span>    {  pagacon - formpagos[0].total >= 0 && <span className='fs-3 text-success'> {  __app.formato_decimal( {total :  pagacon - formpagos[0].total }) }</span> }   </p>
                    </div>
                  
                    <div className=''>
                    
                        <p className=' badge badge-phoenix fs-8 d-block badge-phoenix-success  fs-5'>Total Pagado:   {totalpagado}  </p>
                        
                        { totalCredito  > 0 &&  <p className=' badge badge-phoenix fs-8 d-block badge-phoenix-danger  fs-5'>Credito :  ${ __app.formato_decimal({total : totalCredito}) }  </p>} 


                        { totalpagado - (documento.MATEMATICAS.SUBTOTAL_3 + propina )   -  documento.MATEMATICAS.TOTAL_SALDO_FAVOR    > 0 && <p  className='text-success' >Saldo a Favor:  { totalpagado - (documento.MATEMATICAS.SUBTOTAL_3  + propina ) -    documento.MATEMATICAS.TOTAL_SALDO_FAVOR   }   </p> } 

                        {
                            totalCredito  > 0   && 
                            <>
                                <label htmlFor="">Tipo de Credito:</label>
                                <select onChange={ (e) => SetTipoCredito(e.target.value)} name="banco" defaultValue={tipoCredito}  className="form-select" aria-label="Default select example">
                                  
                               
                                    {lisTipoCreditos.map(data => 
                                          
                                        <option value={data.id}>{data.nombre}</option>

                                    )}
                                </select> 
                                <br/>
                            </>
                        } 

                    </div>

                </div>}



            </div>                    


            {mostrarObcPagos == 1 && <div className="col-7">
                <form className='row' onChange={handleInputChange} >     
                {formpagos.map( (formp,index) =>
                    
                    
                    
                    <div key={index} >   
                    
                  
                        <div className="p-3  row justify-content-center"> 
                            
                            <div className="col-7">
                                <label data-bs-toggle="button" aria-pressed="true" onClick={() =>setPagoAutomatico(index,'total', documento.MATEMATICAS.SUBTOTAL_3 + propina ) } htmlFor="" className='btn btn-subtle-success  btn-sm text-md text-uppercase fs-8'>{formp.nombre}  </label>
                                <input  onChange ={(e) => {console.log(e.target.value)} }  key={index} data-index={index}   data-name="total"  className='form-control'   value={formp.total} />
                            </div>
                            


                            {/* Aqui se selecciona el banco: Tenga en cuanta que
                                Para guardar el efectivo se debe enviar a que caja va ese efectivo,
                                Si el turno ya esta abierto. Entonces la caja DE EFECTIVO por defecto sera 
                                la del turno. En caso de que no exista turno. puede elejir solo entre las cajas de efectivo isponible
                            */}
                            <div className="col-5">
                            {   
                                (user.TURNO.caja_id && formp.id == 1)  &&
                                <>  
                                    Caja: {user.TURNO.caja_id}
                                </>
                                ||
                                <>  
                                    {/*Si la forma de pago es diferente de uno cargamos los bancos. Si no las cajas*/}
                                    {
                                        formp.id != 1 && 
                                       
                                       <>
                                           <label htmlFor="">Banco : {ConsultarBancoCajaxDefecto(formp)}   </label>
                                            
                                            {/* Si es el administrador le cargamso todos los bancos */}
                                            {
                                                user.type_user_d == 1 && 
                                                <>
                                                <select  defaultValue={ConsultarBancoCajaxDefecto(formp)}   data-index={index} data-name="banco_id" className="form-select"  >
                                                    <option value="0">Seleccionar una Opción</option>
                                                    { user.BANCOS.map((banco,index) => 
                                                        <option key={index} value={banco.id}>{banco.nombre} / / {banco.id} </option>
                                                    )}
                                                    <option value="25">25</option>                           
                                                </select>              
                                                </>
                                            }
                                        </>
                                        ||
                                        <>
                                            
                                            <label htmlFor="">Caja</label>
                                            <select defaultValue={formp.banco_defecto_id} data-index={index} data-name="banco_id" className="form-select" aria-label="Default select example">
                                                <option value="0">Seleccionar una Opción</option>
                                                 { user.CAJAS.map((banco,index) => 
                                                    <option key={index} value={banco.id}>{banco.nombre } </option>
                                                )}
                                            </select>
                                        </> 
                                    }
                                  
                                </>           
                            }
                            </div>








                            {
                                false  && 
                                <>
                                    <label htmlFor="">Referencia de Venta</label>
                                    <input   name="referencia"  placeholder='REFERENCIA DE VENTA' className='form-control'  />
                                </>
                            }
                            
                        </div>



                    </div>            
                )}
                </form>
            </div> }
            
           
            { !isNaN(totalpagado)  &&   <button onClick={ handleGuardarPago } className={'btn d-flex  justify-content-center  btn-lg btn-' + documento.TIPO_DOCUMENTO.color_documento  }> Siguiente Paso   </button>}
        </div>
        </div>
    );
}

export default FormaPagoPaso1;