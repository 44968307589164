// Probador.js

import React, { useRef, useState } from 'react';
import { getAlgoritmosDisponibles, Renderizar } from '../Cobros/Render';
import HorasTabla from './BloquesHora';
import TablaTiempos from './TablaTiempos';
import OptionSelect from '../../../Core/Html/OptionSelect';
import TarifasForm from './TarifasForm';


const Probador = () => {
    const [entradaDia, setEntradaDia] = useState("2024-09-29"); // Para el día de entrada
    const [entradaHora, setEntradaHora] = useState("08:00");    // Para la hora de entrada
    const [salidaDia, setSalidaDia] = useState("2024-09-30");   // Para el día de salida
    const [salidaHora, setSalidaHora] = useState("14:30");      // Para la hora de salida
    const [resultado, setResultado] = useState(null);
    const [algoritmo , setAlgoritmo] = useState('Estandar_12h');
    const [ocultarBotones , setocultarBotones] = useState(null);



    const AlgoritmosDisponibles = getAlgoritmosDisponibles();


    const [tarifasbd, setTarifasbd] = useState({
        aux1: 300,
        aux2: 400,
        aux3: 500,
        aux4: 0,
        aux5: 0,
        aux6: 0,
        aux7: 0,
    })


    const simular = useRef();

    const manejarSimulacion = () => {

        setResultado(null)
        setocultarBotones(1);

        const fecha1 = `${entradaDia}T${entradaHora}`;
        const fecha2 = `${salidaDia}T${salidaHora}`;
        
        const resultado  =  Renderizar({
            fecha1 , fecha2 , algoritmo , tarifasbd
        });
    
        console.log(resultado);
        

        setTimeout(()=> {
            setResultado(resultado);
            setocultarBotones(null)
        }, 100);
    };

    const aumentarHoraSalida = (horasAjuste) => {
        const [horas, minutos] = salidaHora.split(":").map(Number); // Divide la hora en horas y minutos
        let nuevaHora = horas + horasAjuste;
        let nuevoDia = new Date(salidaDia);
    
        // Ajustar si la hora pasa los límites de 24 o cae por debajo de 0
        if (nuevaHora >= 24) {
            nuevaHora = nuevaHora % 24;
            nuevoDia.setDate(nuevoDia.getDate() + 1); // Aumenta un día
        } else if (nuevaHora < 0) {
            nuevaHora = 24 + nuevaHora; // Ajusta la hora al retroceder de 00:00
            nuevoDia.setDate(nuevoDia.getDate() - 1); // Retrocede un día
        }
    
        // Actualiza el estado con la nueva fecha y hora
        setSalidaHora(nuevaHora.toString().padStart(2, '0') + ":" + minutos.toString().padStart(2, '0'));
        setSalidaDia(nuevoDia.toISOString().split("T")[0]); // Actualiza el día en formato 'YYYY-MM-DD'
    
        // Llama a la simulación después de actualizar la hora
        setTimeout(() => {
            simular.current.click();
        }, 100); // Simula tras un pequeño retraso para esperar que el estado se actualice
    };






    const handleChange = (e) => {
        const { name, value } = e.target;
        setTarifasbd({
            ...tarifasbd,
            [name]: Number(value), // Convertir el valor a número
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes agregar la lógica para procesar las tarifas
        console.log('Tarifas enviadas:', tarifasbd);
    };



    return (
        <div className="container">
            <h1>Simulador de Cobro</h1>

            <div className="row">
                {/* Columna para la entrada de datos */}
                <div className="col-md-6">
                    
                    <div className='row'>
                        {/* Entrada para el día */}
                        <div className="mb-3 col-md-6">
                            <label htmlFor="entradaDia" className="form-label">Fecha de Entrada:</label>
                            <input
                                id="entradaDia"
                                type="date"
                                className="form-control"
                                value={entradaDia}
                                onChange={(e) => setEntradaDia(e.target.value)}
                            />
                        </div>

                        {/* Entrada para la hora */}
                        <div className="mb-3 col-md-6">
                            <label htmlFor="entradaHora" className="form-label">Hora de Entrada:</label>
                            <input
                                id="entradaHora"
                                type="time"
                                className="form-control"
                                value={entradaHora}
                                onChange={(e) => setEntradaHora(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        {/* Salida para el día */}
                        <div className="mb-3 col-md-6">
                            <label htmlFor="salidaDia" className="form-label">Fecha de Salida:</label>
                            <input
                                id="salidaDia"
                                type="date"
                                className="form-control"
                                value={salidaDia}
                                onChange={(e) => setSalidaDia(e.target.value)}
                            />
                        </div>

                        {/* Salida para la hora */}
                        <div className="mb-3 col-md-6">
                            <label htmlFor="salidaHora" className="form-label">Hora de Salida:</label>
                            <input
                                id="salidaHora"
                                type="time"
                                className="form-control"
                                value={salidaHora}
                                onChange={(e) => setSalidaHora(e.target.value)}
                            />
                        </div>

                        Algoritmo: {algoritmo}
                        <select value={algoritmo} onChange={(e) => setAlgoritmo(e.target.value)} className='form-control'>
                            <OptionSelect  datos={AlgoritmosDisponibles} />
                        </select>

                        <TarifasForm  tarifasbd={tarifasbd}  handleChange={handleChange} />
                        
                    </div>

                    {!ocultarBotones && <p>
                        <button className="btn btn-secondary" onClick={ () => aumentarHoraSalida(-1)}>  hora Anterior </button>
                        <button className="btn btn-primary me-2" ref={simular} onClick={manejarSimulacion}>Simular</button>
                        <button className="btn btn-secondary" onClick={ () => aumentarHoraSalida(1)}> hora siguiente</button>
                        
                        </p>}
                  
                </div>

                <div className='col-md-6'>
                    <TablaTiempos resultado={resultado} />
                </div>

                <HorasTabla  resultado={resultado} />
                   
              
            </div>
        </div>
    );
};

export default Probador;
