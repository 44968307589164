import Matematicas from "./Matematicas";
import {__app} from "../../utils/myApp";
import {enviarPeticion} from "../../Core/ActionDatabase";
import {    getExtraInitial, SetinitialStateDocumento} from "./controlador";
import { NotificationManager} from 'react-notifications';
import { recentOpenFacRef , getNit} from "../../Core/FireBase"
import {  get , child , ref , query, set,getDatabase } from "firebase/database";
import { ExtraerTablabyIds } from "../../Core/Dixie";
import { setCantidadInventario } from "../Productos/Controlador";
import { Numpositivo, obtenerValorAntesDeImpuestos } from "../../Core/MatematicasGlobales";


export class DocumentL extends Matematicas {

    constructor(DOCUMENT , KEY_LOCAL_STORAGE  , DISPAC , USUARIO = null ) {


        console.log("SOY DocumentL")
        console.log(DISPAC)
        /**   */
        super();
       
        if ( DOCUMENT ){
           
        
            this.DISPAC = DISPAC

            this.DOCUMENT =   JSON.parse( JSON.stringify(DOCUMENT)); 


            /*** Con esta key vamos a intercambiar entre el precio de Costo o Precio de Venta  */
            this.KEY_PRECIO  = ( this.DOCUMENT.TIPO_DOCUMENTO ) ? this.DOCUMENT.TIPO_DOCUMENTO.KEY_PRECIO : null;

            
            /**** Configuraciones Genarles */
            this.OBLIGAR_DESCUENTO_GLOBAL = 0;
    
            


            this.USUARIO = USUARIO;


            /**** Configuraciones Genarles */
            this.CONFIG = {
                
                /*** Porcentaje minimo para Aplicar en forma general  */
                PORCENTAJE_MINIMO_DESCUENTO_PERMITODO : 10,


                /**** Precio minimo para realizar descuentos */
                PRECIO_MINIMO_DESCUENTO_PERMITODO : 5000
            }
        
        }

        this.varlog = 1
    }


    actualizarTipoDocumento (tipo){
    
        this.DOCUMENT.TIPO_DOCUMENTO = tipo;
        if(this.DISPAC){
            this.DISPAC.dispatch({
                type :  this.DISPAC.actionTypeDocument.SET_DOCUMENTO,
                documento : this.DOCUMENT
            });
        }
    }


 



    /*** Ejecuta  */
    despachador ( nombre_funcion , parametros ){  
        
        this.log("F: despachador")
        this.log("--------------------------")
        this.log("--------------------------")
        this.log("--------------------------")
        
        console.log( "Nombre de la funcion : " ,  nombre_funcion);
        
        let result = null;
        result =  this[nombre_funcion](parametros);
        
        
        console.log(  this.DOCUMENT  );

        this.RenderizaryGuardar(parametros , nombre_funcion);
        return result;
    }


    RenderizaryGuardar = (parametro , nombre_funcion) => {       
      
        this.log("F:RenderizaryGuardar..acontinuacion variable parametro");
        this.log(parametro);
        
        
        let valor = undefined;

        try {
            valor = parametro.valor
        } catch (error) {
            console.log(error);
        }



        let renderizar_total = true;

        if( valor !== undefined){

            if(typeof(valor.renderizar_total)){
                if ( valor.renderizar_total !== undefined   ){
                    renderizar_total =  valor.renderizar_total
                }    
           }
        }



   
        if(renderizar_total){
            this.renderizarTotal(nombre_funcion);
        }

        if( this.DISPAC){
            this.DISPAC.dispatch({
                type :  this.DISPAC.actionTypeDocument.SET_DOCUMENTO,
                documento : this.clonarObjeto(this.DOCUMENT)
            });
        }
    }


    insertarStockItems = (data) => {
        let estacion_id = 1;

        let onlyEstacioeId = data.filter( (data) => data.estacione_id  == estacion_id ) 
      
        onlyEstacioeId.map((infoProducto) => {
           
            let producto_id  = infoProducto.producto_id;
            let cantidad = this.FN(infoProducto.cantidad);

            this.insertarStock({producto_id , cantidad});

       })  

    }

    insertarStock = ({producto_id , cantidad}) => {
        
        // Supongamos que tienes un valor de stock actual
        const stock_actual = cantidad;

        // Supongamos que tienes el array de objetos
 

        let aux = {};
        // Iterar sobre el array de objetos
        for (let i = 0; i < this.DOCUMENT.PRODUCTOS.length; i++) {
            const producto = this.DOCUMENT.PRODUCTOS[i];
            // Verificar si el valor de la propiedad 'id' es igual al valor de la propiedad 'aux'
            if (producto.id == producto_id) {
            
                // Agregar la propiedad 'MATEMATICAS.STOCK' con el valor de 'stock_actual'
                this.DOCUMENT.PRODUCTOS[i].MATEMATICAS  = {
                    ...producto.MATEMATICAS,
                    STOCK: stock_actual
                };
            }
        }

        //this.DOCUMENT.PRODUCTOS.MATEMATICAS 
        console.log( aux);
        console.log( this.DOCUMENT);
        console.log( this.DOCUMENT);
        console.log( this.DOCUMENT);
        console.log( this.DOCUMENT);

    }


    set_stock_documento_ajax({indexx, valor}){

        const anterior =  this.DOCUMENT.PRODUCTOS[indexx].MATEMATICAS.STOCK;
        this.DOCUMENT.PRODUCTOS[indexx].MATEMATICAS.STOCK =   this.FN( valor ) ;

        if(anterior != valor){

            let  producto_id     =    this.DOCUMENT.PRODUCTOS[indexx].id;
            let  estacione_id      =    this.USUARIO.TURNO.estacione_id;
            let  cantidad         =    valor;
            let  observacion      =     "Ajustado desde una compra.";
            let  cantidad_actual  =    anterior; 
            let user_id           =  this.USUARIO.USER.id
            setCantidadInventario({producto_id,estacione_id,cantidad,observacion, cantidad_actual,user_id});
        }
    }



    CambiarPrecioVentaShowCompra = (infoPrecioid) => {
     
        // Inicializar this.DOCUMENT.COMPRA si aún no está definido
        if ( !(this.DOCUMENT.ESTRAS &&  this.DOCUMENT.ESTRAS.COMPRA &&  this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra) ) {
            
            this.DOCUMENT.ESTRAS.COMPRA = {
                lista_precio_activa_compra : 1
            };
        }
        
        // Actualizar el precio de venta mostrando la compra
        this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra = infoPrecioid;
       
       
    
        // Realizar otras acciones relacionadas con el cambio de precio de compra
        this.compraPreciosSwitch(infoPrecioid);
    }





    
    insertarItem = ( {item , options}  ) => {   

        
        
        let nuevoItem = this.clonarObjeto(item);


        let resultado = this.consultarSiExisteItem(nuevoItem);

   
        if ( resultado >= 0 ) {

            this.sumarItemLinea(resultado , options );
            this.DOCUMENT.ESTRAS.ultimoIndex = resultado;
            this.DOCUMENT.ESTRAS.ultimoIndexCantidad =  this.DOCUMENT.PRODUCTOS[resultado].MATEMATICAS.CANTIDAD  + this.FN( options.cantidad );
       
        }else{    

            this.insertarPorPromeraVez(nuevoItem , options );
            this.DOCUMENT.ESTRAS.ultimoIndex =  this.DOCUMENT.PRODUCTOS.length  - 1 ;         
        }
        
        
    }


    consultarSiExisteItem = (item) => {
        

        /*** Si Comprieba que no este agregado */
        let Indexactual = -1;
        for (let index = 0; index < this.DOCUMENT.PRODUCTOS.length; index++) {
            if( this.DOCUMENT.PRODUCTOS[index].id === item.id ){
                Indexactual = index;
            }    
        }


        /*** Si fue cambiado el nombre o el precio del productos */
        if( Indexactual >= 0  ){
            if( this.DOCUMENT.PRODUCTOS[Indexactual].DOCUMENTO.OTRO_PRODUCTO){
               return  -1;
            }
        }










        /*** Si es un Servicio. */
        if ( item.tipo_producto == 3 ) {
            return  -1;
        }


        /** Si es un item de check List *** */
        if ( item.auxRestauranteListCheck == 1 ) {
            return  -1;
        }



         /** Si es un item de check List *** */
         if ( item.multi_item && item.multi_item  == 1 ) {
            return  -1;
        }


        /*** Si existe este Campo, sinifica que este documento ya fue Comandado y no podemos sumarlo */
        if( this.DOCUMENT.FORM && this.DOCUMENT.FORM.HoraComanda && this.DOCUMENT.FORM.HoraComanda.length >= 10  ){
           
            console.clear();
           console.log( this.DOCUMENT.PRODUCTOS[Indexactual]);
           console.log(item);

           
            // Si encontro el producto procedemos a preguntar si es la misma referencia.
            if(Indexactual >= 0 ){
               
                /** Si los productos no se encuentras en la misma Referencia no se pueden sumar. */
                if(this.DOCUMENT.PRODUCTOS[Indexactual].fechaid != item.fechaid ){
                    return  -1;
                }
            }
            


            //return  -1;
        }
       


        return Indexactual;
      
    }


    insertarPorPromeraVez = (item , options ) => {


    

        if ( options.PRECIO &&   parseFloat(options.PRECIO) > 0     ){

            item.PRECIO =  parseFloat(options.PRECIO);
      
        }

   

        const  {documento , matematicas}  =  this.primerFormato(item , options );
        

        /** Si hay descuento debemos modificar las siguientes variables */
        /*
            DESCUENTO_UNITARIO : 1500,
            DESCUENTO_UNITARIO_PORCENTUAL : 10,
            DESCUENTO_DIRECTO  : 1,
            IGNORAR_DESCUENTO_PORCENTAJE : 1,
        */
        /*** Esta funcion agrega un descuento por defecto no porcentual. */
        if (options.DESCUENTO_UNITARIO && options.DESCUENTO_UNITARIO > 0 ){
            matematicas.DESCUENTO_UNITARIO = options.DESCUENTO_UNITARIO;
            matematicas.DESCUENTO_UNITARIO_PORCENTUAL =  this.extraerPorcentaje( options.DESCUENTO_UNITARIO , matematicas.PRECIO  );
            matematicas.DESCUENTO_DIRECTO = 1; 
            matematicas.IGNORAR_DESCUENTO_PORCENTAJE = 1;

        }


        /*** Agregado el 29/03/2024
         * 
         * esta linea es para que el usuario pueda insertar un item con un descuento por Defecto
         * Ejemplo: Si aplica un descuento para la categoria X desde la configuracion de la categoria.
         * 
         * 
         */
        if (options.DESCUENTO_UNITARIO_PORCENTUAL && options.DESCUENTO_UNITARIO_PORCENTUAL > 0 ){
            matematicas.DESCUENTO_UNITARIO_PORCENTUAL = options.DESCUENTO_UNITARIO_PORCENTUAL;
        }else{
            console.log(matematicas);
        }

        


  

        item.DOCUMENTO   =  documento;
        item.MATEMATICAS =  this.clonarObjeto(matematicas);
        
  
        if(this.DOCUMENT.TIPO_DOCUMENTO.LIQUIDABLE && this.DOCUMENT.TIPO_DOCUMENTO.LIQUIDABLE == 1 ){
            
            let listaprecioid = 0;
            
            if( this.DOCUMENT.ESTRAS.COMPRA &&   this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra){

                listaprecioid = this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra;
            }
        
            item.COMPRA = this.compraPreciosSwitch2(item , listaprecioid)
        }
        this.DOCUMENT.PRODUCTOS = [ ...this.DOCUMENT.PRODUCTOS , item ];


        
     
        
    }

    recalcularPreciosItems = () => {
     
        this.DOCUMENT.PRODUCTOS = this.DOCUMENT.PRODUCTOS.map(  (producto , index) => {
            //console.log()
        })
    }



    sumarItemLinea = (index , options ) => { 

        this.DOCUMENT.PRODUCTOS[index].MATEMATICAS.CANTIDAD +=   this.FN( options.cantidad ) ;
       
    }



    /*

        listaIDview : Codigo de la Lista de Precio
        SWITCH_LISTA_PRECIO : (Si es por porcentaje o precio )
        Acccion : En caso de que esta funcion se llame desde quitar Cliente: Tambien se debe elimnar
    */
    actualizar_lista_precio = (listaIDview, SWITCH_LISTA_PRECIO = null , acccion= null ,  tercero = null , cambiar_precio = true ) => {
        
        if(!cambiar_precio){
            listaIDview = 0;   
        }


        if( acccion === 'quitarCliente'){
         
            this.DOCUMENT.TERCERO = null;
            this.DOCUMENT.MATEMATICAS.TOTAL_SALDO_FAVOR  = 0;

        }

        if(acccion == 'agregarTercero'){


            this.DOCUMENT.TERCERO = tercero;
        }
        //listaIDview ID de la Lista de precio Obtenido desde la vista

        //SWITCH_LISTA_PRECIO Alterna si es una lista de precio en Porcentaje

  

        this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA =  listaIDview;
        this.DOCUMENT.ESTRAS.SWITCH_LISTA_PRECIO =  SWITCH_LISTA_PRECIO;
        
        this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL   =  0;
       
        this.DOCUMENT.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL  = 0;

 



       const   productos = this.clonarObjeto( this.DOCUMENT.PRODUCTOS).map(  (pro , index) => {       
           
            if(pro.LISTA_PRECIOS){

                let {mewprecio , descuento}  =  this.extraerPreCiobyLista(listaIDview, SWITCH_LISTA_PRECIO,pro);
                
                console.log("PREICO A : " + mewprecio)
                /*** Evitamos precios 0 y precios negativos cuando es un precio fijo */
                console.log("PREICO: Resultado !(mewprecio >  0) -->" + !(mewprecio >  0))
                console.log("PREICO: Resultado !(SWITCH_LISTA_PRECIO ==  2) -->" + !(SWITCH_LISTA_PRECIO ==  2))
               
                if( mewprecio <=  0) {

                    mewprecio = pro.MATEMATICAS.PRECIO
                }

                console.log("PREICO B : " + mewprecio)



               if(Number(descuento) < 0 ){

                    descuento = Number(descuento) * -1;
               }

                
                /*** Asignamos el Precio en caso tal la lsita de precio se un precio directo */
                let matematicas = {...pro.MATEMATICAS , DESCUENTO_UNITARIO_PORCENTUAL : descuento  , PRECIO : mewprecio }
               
                pro  = {...pro , MATEMATICAS : matematicas  };

                if( SWITCH_LISTA_PRECIO == 1  ) {
                    /*
                    let matematicas2 = {...producto.MATEMATICAS , DESCUENTO_UNITARIO_PORCENTUAL : 10 }
                    console.log(matematicas2)
                    producto = {...producto , MATEMATICAS : matematicas2  };
                    */       
                }

                console.log(pro);
               
            }

            return pro;
        })
    
        console.log(productos);


        
        if(cambiar_precio){
            this.DOCUMENT.PRODUCTOS = this.clonarObjeto(productos);
        }
        this.RenderizaryGuardar();
    }


    /*** Esta Funcion devuelve EL PRECIO DEL PRODUCTO Y UN PORCENTA 
     * 
     * Pueda que devuelva el mismo precio del item en caso tal la lista de precio sea configurada como porcentaje
     * o no como lista. SWITCH_LISTA_PRECIO controla esta variable
    */

    /* RECIBE

        listaIDview = Es el codigo de la lista de precio

        SWITCH_LISTA_PRECIO = Es el alternador que define si el precio a extraer es un procentaje o directo.

        producto =  Es un Arregle donde se encuentra almasenado la lista de preioc
    */
    extraerPreCiobyLista = (listaIDview, SWITCH_LISTA_PRECIO,producto) => {
       
        let mewprecio =  producto[ this.KEY_PRECIO ];
        let descuento =  0;

     
        /** Si Existe lista de precio */
        if( producto.LISTA_PRECIOS && Number(listaIDview) > 0  ){

            console.log("___ a")

            const listaprecio  = producto.LISTA_PRECIOS.find(lista => (lista.ct_lista_precio_id  ==  listaIDview  ) );
            if(!listaprecio){
                console.log("___ B")
                return  {mewprecio , descuento} ;
            }


            /*** Acer un Descuento */
            if( SWITCH_LISTA_PRECIO === 1  ) {

                console.log("___ C")
                this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA      = listaprecio.ct_lista_precio_id;
                descuento                                     = listaprecio.precio;
                mewprecio =  producto[ this.KEY_PRECIO ];
            }


            /*** En caso tal no se un descuento si no una lista de precio  */
             if( SWITCH_LISTA_PRECIO === 2  ) {
                console.log("___ D")
                mewprecio =  listaprecio.precio;;
            }
            return  {mewprecio , descuento} ;
        }
        
        if(Number(listaIDview) == 0){
            descuento = 0
            mewprecio =  producto[ this.KEY_PRECIO ];
            console.log("___ E")
            return  {mewprecio , descuento} ;
        }
     
        descuento = 0
        mewprecio =  producto[ this.KEY_PRECIO ];
        console.log("___ F")
        return  {mewprecio , descuento} ;
       
    }

 

    
    /** Actualizar saldo a favor */
    AgregarSaldoAFavor = ( {anticipos , devoluciones } ) => {

        let notasCreditos = devoluciones
       
        let sumaDevoluciones = notasCreditos.reduce((acumulador, objeto) => acumulador + parseInt(objeto.total)  , 0);
        let sumaAnticipos = anticipos.reduce((acumulador, objeto) => acumulador + parseInt(objeto.total)  , 0);



        
        this.DOCUMENT.MATEMATICAS.TOTAL_SALDO_FAVOR          = Numpositivo(sumaDevoluciones) +  Numpositivo(sumaAnticipos) ;
        this.DOCUMENT.TERCERO.SALDO_A_FAVOR = notasCreditos;
        this.DOCUMENT.TERCERO.ANTICIPOS     = anticipos;

        
        if( this.DISPAC){
            this.DISPAC.dispatch({
                type :  this.DISPAC.actionTypeDocument.SET_DOCUMENTO,
                documento : this.clonarObjeto(this.DOCUMENT)
            });
        }
       

    }

    /** Agrega un tercero automaticamente, para ventas rapidas o lista de precios...  */
    AgregarTerceroDirecto = (tercero) => {
        /***
         *  1=> Activar porcentaje
         *  2=> Activar precio  
         */
        let SWITCH_LISTA_PRECIO = 1;


        try {
            if(    this.USUARIO.CONFIGURACIONES_TABLE.switch_modo_precio &&   this.USUARIO.CONFIGURACIONES_TABLE.switch_modo_precio == 2 ){
                
                SWITCH_LISTA_PRECIO = 2;
            }
        } catch (error) {
            console.log(error);
        }
    

        this.actualizar_lista_precio(tercero.ct_lista_precio_id , SWITCH_LISTA_PRECIO ,  'agregarTercero' , tercero);
  
    }


    /** Aplica un descuento en precio y lo convierte en porcentaje */
    aplicar_descuento_linea_precio = (data) => {
        this.aplicardescuentolineaprecio(data);
    }

    aplicar_descuento_linea_porcentaje = (data) => {
        this.aplicardescuentolineaporcentaje(data);
    } 

    cambiar_precio_documento = (data) => {
        this.cambiarPrecioDocumento(data);
    }
    


    cambiar_precio_documento_from_compra = (data) => {
        this.cambiarPrecioDocumentoFromCompra(data);
    }

    /** Resta los impuesto y entrega un Valor total */
    cambiar_precio_documento_subtotal_3({indexx , valor }){
        let impuesto = this.DOCUMENT.PRODUCTOS[ indexx ].MATEMATICAS.IMPUESTOS;
        let valorSinImpuestos = obtenerValorAntesDeImpuestos( valor , impuesto );
        this.cambiarPrecioDocumento({
            indexx : indexx,
            valor : valorSinImpuestos
        }) 
    }

    aplicar_cambio_cantidad = ( data ) => {
        this.AplicarCambioCantidad(data);
    }


    aplicar_descuento_total_linea_precio = ( data ) => {
        this.aplicarDescuentoTotalLineaPrecio(data);
    }

    aplicar_descuento_globla = (data ) => {
        this.aplicarDescuentoGlobla(data);
    }
    

    eliminar_impuesto_linea = (data ) => {
        this.eliminarImpuestoLinea(data);
    }
    

    eliminar_linea = (data) => {
        this.eliminarLinea(data);
    }
    

    eliminar_todos_productos = () => {
        this.eliminartodosproductos();
    }


    cambiar_tipo_documento = (data) => {
    this.cambiarTipoDocumento(data);
    }


    editar_linea_item_completa = ({ item , indexx  } ) => {

        this.DOCUMENT.PRODUCTOS[ indexx ] = item;
    }


    cambiar_nombre_linea_item = ({valor , indexx}) => {      
        valor = valor.trim();
        if ( valor  !=   this.DOCUMENT.PRODUCTOS[ indexx ].nombre.trim()   ){        
            this.DOCUMENT.PRODUCTOS[ indexx ].DOCUMENTO.OTRO_PRODUCTO = true;    
            this.DOCUMENT.PRODUCTOS[ indexx ].nombre = valor;
        }
    }


    cambiar_descripcion_linea_item = ({valor , indexx}) => {      
        valor = valor.trim();
        if ( valor  !=   this.DOCUMENT.PRODUCTOS[ indexx ].descripcion.trim()   ){        
            this.DOCUMENT.PRODUCTOS[ indexx ].DOCUMENTO.OTRO_PRODUCTO = true;    
            this.DOCUMENT.PRODUCTOS[ indexx ].descripcion = valor;
        }
    }


    
    cambiar_cuenta_contable = ({valor , indexx}) => {   
        valor = valor.trim();
        this.DOCUMENT.PRODUCTOS[ indexx ].cuenta_contable = valor;
     
    }



    cambiar_variante_item = ({indexx , variantes }) => {      
       
        this.DOCUMENT.PRODUCTOS[ indexx ].VARIANTES = variantes 
    }




    cambiar_operador_linea_item = ({valor , indexx}) => {      
        
        this.DOCUMENT.PRODUCTOS[ indexx ].DOCUMENTO.OTRO_PRODUCTO = true;    
        this.DOCUMENT.PRODUCTOS[ indexx ].nombre_operador = valor.nombre;
        this.DOCUMENT.PRODUCTOS[ indexx ].operador = valor.id;
    }

    actualizar_vendedor = ({valor}) => {
        const {vendedor, nombre_vendedor} = valor;
        if( this.DOCUMENT.FORM === null){
            this.DOCUMENT.FORM  = {
                vendedor_id : vendedor,
                nombre_vendedor : nombre_vendedor
            }
        }else{
            this.DOCUMENT.FORM.vendedor_id = vendedor;
            this.DOCUMENT.FORM.nombre_vendedor = nombre_vendedor;
        }   
    }


    actualizar_mesa = ({valor}) => {
    
   
        const {mesa_id, mesanombre} = valor;
    
        if( this.DOCUMENT.FORM === null){
            this.DOCUMENT.FORM  = {
                mesa_id : mesa_id,
                mesanombre : mesanombre
            }
        }else{
            this.DOCUMENT.FORM.mesa_id = mesa_id;
            this.DOCUMENT.FORM.mesanombre = mesanombre;
        }   
    }



    clonarObjeto = (data) => {
        return  JSON.parse( JSON.stringify(data) );
    }


    log = (data) => {
        if(this.varlog == 1){
            console.log(data);
        }
    }


    guardarDocumento =  async (user) => {
        
 

        if(this.DOCUMENT.PRODUCTOS.length < 1){
            alert("Seleccione Items para continuar.")
            return false;
        }




     



        let copiaDC = this.clonarObjeto(this.DOCUMENT); 

        let data =  { 
            "nombreCajero"    :  user.USER.nombre,
            "Operadores"      : __app.objetoATexto( this.DOCUMENT.PRODUCTOS , 'nombre_operador' ),
            "HoraComanda"     : __app.getFecha(),
        };


        //Si este usuario es un vendedor directo lo agregamos como vendedor
        if(user.USER.vendedor ==  1){
    
            data = {...data ,...{
                "vendedor_id" :user.USER.id,
                "nombre_vendedor" : user.USER.nombre
            }}

        }  
  


        //Si encontramos de que esta factura ya tiene una fecha no la agregaremos
        if(copiaDC.FORM && !copiaDC.FORM.FechaHoraLocal ){
            data.FechaHoraLocal  =  __app.getFecha();
        }

        

        // Modificamos la variable numberid para solo imprimir los ultimos productos de la comanda, o los nuevos.
        let productos = copiaDC.PRODUCTOS.map( (producto , index) => {
            if (!producto.numberid ){
                producto.numberid =   __app.numeroFecha(data.HoraComanda);
            }
            return producto;
        })
        copiaDC.PRODUCTOS =  productos;

       
       
        let pasaAux = 0;
        let FORM = {  ...copiaDC.FORM , ...data  }
        copiaDC  = { ...copiaDC , FORM  };


      

       
        if( copiaDC.FORM &&  copiaDC.FORM.mesa_id &&  Number(copiaDC.FORM.mesa_id) >  0 ){
            pasaAux += 0;
        }else{
    
            pasaAux += 1;
            alert("Seleccione Mesa para continuar")
        }
        

        if( copiaDC.FORM &&  copiaDC.FORM.vendedor_id &&  Number(copiaDC.FORM.vendedor_id) >  0 ){
            pasaAux += 0;      
        }else{
            pasaAux += 1;
            alert("Seleccione Vendedor ")
        }
        
     
        if(pasaAux === 0){
          
            this.addAutoincremente(copiaDC , user );

        }

    }

    
    addAutoincremente = async (copiaDC , user) => {


        //Con esta linea estamos evitando de que nos genere un nuevo autoincrementable por cada ediccion de la cuenta
        if (copiaDC.cuentAbiertaFirebaseId && Number(copiaDC.cuentAbiertaFirebaseId) >=  0 ){
            this.cuentasAbiertas(copiaDC , copiaDC.cuentAbiertaFirebaseId  , user );
            return false;
        }

        this.autoincrementableFireBase(user.TURNO.id).then((data)=> {
            
          
            if (data == 0 ){

                this.cuentasAbiertas(copiaDC , 1 , user );
            }else{
                
     
                  this.cuentasAbiertas(copiaDC , data + 1  , user )
            }
        });
        // this.FireBaseAddAutoIncrement();
       
    }


    cuentasAbiertas = (data , id , user  ) => {
        
        const db = getDatabase();

        const listener = ref(db, 'cambiosdatabase/'+getNit()+'/cuentas_abiertas/punto_venta_'+ user.ESTACION.id + '/' + data.id ) 
       

        data.cuentAbiertaFirebaseId = id;

        set(listener  , data  ) .then(() => {
            
            console.log("Data saved Cuenta avierta!"); 
            
            this.FireBaseMoverAutoIncrement(id , user.TURNO.id);

            NotificationManager.success('Numero de comanda #' + id, 'Pedido Realizado');
            
            
        
            let initial = getExtraInitial();

            const aux = SetinitialStateDocumento();

            const copiaDC  = {...aux , ...initial}
            

            console.log(copiaDC );

            localStorage.setItem(this.DOCUMENT.KEYDOCUMENT.keyLocalStogere , JSON.stringify(copiaDC) );
           

            if( this.DISPAC){
                this.DISPAC.dispatch({
                    type :  this.DISPAC.actionTypeDocument.SET_DOCUMENTO,
                    documento : { ...copiaDC , KEYDOCUMENT : this.DOCUMENT.KEYDOCUMENT }
                });
            }
           

        })
        .catch((error) => {
            // The write failed...
        });
    
    }



    /** Crea un autoincrementable por cada turno */
    autoincrementableFireBase = (turno_id) => {
        
        const dbRef = ref(getDatabase());
        return get(child(dbRef, 'cambiosdatabase/'+getNit() + '/autoincremente/cuenta_abierta/turnos/' + turno_id )).then((snapshot) => {
            if (snapshot.exists()) {
             
                return snapshot.val().id;
            } else {
                console.log("No data available");
               
                return 0;
            }
        }).catch((error) => {
            console.error(error);
        });
    }


    FireBaseMoverAutoIncrement = (ids , turno_id) => {

        const db = getDatabase();
        let turno = turno_id;
        const listener = ref(db, 'cambiosdatabase/'+getNit()+'/autoincremente/cuenta_abierta/turnos/' + turno_id ) 
        
        console.log("listo")
        set(listener  , {id : ids}  ) .then(() => {
            console.log("Data saved successfully!");     
        })
    }
    

    /** Inicia un nuevo Documento  
     *  Esta funcion sera usada cuando tenemos cargada una cuenta abierta en un documento y queremos cerrarla
    */
    cancelarCuentaActual = () => {

        let initial = getExtraInitial();
        const aux = SetinitialStateDocumento();
        const copiaDC  = {...aux , ...initial}
        
        
        console.log(copiaDC );

        localStorage.setItem(this.DOCUMENT.KEYDOCUMENT.keyLocalStogere , JSON.stringify(copiaDC) );
        
        if( this.DISPAC){
            this.DISPAC.dispatch({
                type :  this.DISPAC.actionTypeDocument.SET_DOCUMENTO,
                documento : { ...copiaDC , KEYDOCUMENT : this.DOCUMENT.KEYDOCUMENT }
            });
        }
        
    }



    /***
     * 
     * Agregar Todos los itemss.
     */
    agregarMultiItems = async ( productos_consumidos ) => {

        /*let ejemplo = [

            { id : 1 , cantidad : 10 , precio : 3000},
            { id : 2 , cantidad : 20, precio : 4000},
            { id : 3 , cantidad : 30 , precio : 5000},
        ]*/

        let soloIDs = productos_consumidos.map(item => item.producto_id);

        console.log(soloIDs);

        var   data = await  ExtraerTablabyIds("productos" , soloIDs  );

       
        this.insertarItemsConEspera(data, productos_consumidos);

    }



    insertarItemsConEspera = async (data, productos_consumidos) =>  {
        for (let index = 0; index < data.length; index++) {
           
            const item = this.clonarObjeto(data[index]);
            

           


            const objeto = productos_consumidos.find((element) => parseFloat(element.producto_id) === parseFloat(item.id));
          
            console.log( objeto );
    
            
            const datos = this.clonarObjeto( { item: item, 
                options: { 
                    cantidad: objeto.CANTIDAD ,  
                    DESCUENTO_UNITARIO : parseFloat(objeto.DESCUENTO) * -1,
                    PRECIO : objeto.PRECIO
                } 
            } );

    
            // Esperar 100 milisegundos antes de la siguiente iteración
            await new Promise((resolve) => {
                setTimeout(() => {
                    this.despachador('insertarItem', datos);
                    resolve();
                }, 100);
            });
        }
        
    }
    
    // Llamada a la función




    
} 