import { NotificationManager } from "react-notifications";
import { ExtraerTabla, ExtraerTablabyIds, reloadDatabases } from "../../../../Core/Dixie";
import { fetchUSer, getLocalstore, setLocalStore } from "../../../../Core/fetchLocalStoreData";
import { generarDocumentoFantasma, guardarDocumentoLocal } from "../../../Document/controlador";
import { DocumentL } from "../../../Document/DocumentCore";
import { __app } from "../../../../utils/myApp";
import { Renderizar } from "../../Cobros/Render";



export class Parqueadero{

    constructor() {
        
        

        /*** El tipo de documento ID es el documento que por defecto a parecera el parqueadero */
        this.TIPO_DOCUMENTO_ID = 1;


        this.USER = fetchUSer();
        console.log(this.USER)
        
     

        this.PLACA = '';


        /*
        Este Codigo es el servicio del parqueadero que por defecto ira incluido en todas las facturas de tipo parqueadero
        */
        this.CODIGO_SERVICIO_PARQUADERO = "5";


        this.VEHICULO = {
        
        };


        this.DOCUMENTO = {

        };


        this.KEY_LOCAL_STORE = "salida_parqueadero";


        this.KEYDOCUMENT = 
        {
            "keyLocalStogere": this.KEY_LOCAL_STORE ,
            "precio":0,
            "tabKey":0,
            "color_documento":"ninguno",
            "etiqueta_corta":"Parqueadero"
            
        }
        


        this.DISPATCH       = null;

        /**
         * La logica del documento principal que con tiene todas las matemaricas
         */
        this.LOGICADOCUMENTO = null ;


        this.RESPUESTA_ALGORITMO_PARQUEADERO = null;

    }


    async renderizarPlaca (placa){
        
        this.USER = fetchUSer();


        this.log('Iniciando Proceso de renderizacion para la placa : ' +  placa )

        if (typeof placa === 'undefined' || placa.length < 3) {
            console.log("La placa no está definida o tiene menos de 3 caracteres.");
            return 3;
        }

        this.PLACA = this.formatearPlaca(placa); 

        console.log("Resultado de placa Formateada : " +   this.PLACA )

        //Preguntar si Existe
        let resultado = await  this.validarExistenciaPlaca();

        if(resultado){

            let estaenPatio = await this.consultarVehiculoenPatio();;
            if(estaenPatio === 1){
                return 2;
            }else{
                return 1;
            }
            
            
            
        }else{
            
            return 0;
        }


    }


    formatearPlaca(placa) {
        // Eliminar todos los caracteres que no sean letras o números
        let placaFormateada = placa.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();;
        
        // Retornar la placa formateada
        return placaFormateada;
      }


    //1 En patio
    //0 No en patio
    async consultarVehiculoenPatio() {
        this.log("Consultado vehiculo en Patio");
    
        let opciones = {
            condiciones: {
                where: 'placa',
                igual_a: this.PLACA,
            }
        };
    
        const documentoByplaca = await ExtraerTabla('documentos', 1, opciones);
        
        if (documentoByplaca && documentoByplaca.length > 0) {
            
            this.log("Encontramos un registro");
            console.log(documentoByplaca);
    
            for (const data of documentoByplaca) {
                if (data.estado_patio == 1) {
                    this.log("El vehiculo está en Patio");
    
                    // Guardamos en el local storage
                    setLocalStore(this.KEY_LOCAL_STORE, data);
    
                    return 1; // Retornamos 1 si el vehículo está en el patio
                } else {
                    this.log("El vehiculo existe en cancha pero no está en patio.");
                }
            }
    
            // Si no se encontró ningún vehículo en el patio, retornamos 0
            return 0;
        } else {
            this.log("No encontramos ningún registro");
            return 0;
        }
    }
    




    async registrarVehiculo(vehiculo){

        console.log("Iniciendo registro del vehiclo");
        await this.registrarVehiculoLocalStore(vehiculo);

    }


    async registrarVehiculoLocalStore(vehiculo){

        console.log("Iniciendo registro del vehiclo en el local Store");
        reloadDatabases( [vehiculo] ,  'vehiculos'  ) 
  
    }
   



    async validarExistenciaPlaca  () {
        this.log('Validando su existencia' )
        var   data = await  ExtraerTablabyIds("vehiculos" , [ this.PLACA ]  , 'identificacion' );
        this.log('La siguiente linea es el resultado de la consulta' )
        this.log(data)

        if(data.length > 0 ){
            this.log('La placa fue encontrada exitosamente' );
            this.setVehiculo(data[0]);
            return true;
        }else{
            this.log('La placa no fue  encontrada ' )
            return false;
        }
    }


    setVehiculo(infoVehiculo) {
        this.log('El vehiculo fue Asignado a la Clase del parqueadero' );  
        
        infoVehiculo.nombreCategoria = this.getNombreCategoriaVehiculo(infoVehiculo.objetos_categoria_id);


        this.VEHICULO = infoVehiculo;
    }


    getNombreCategoriaVehiculo(categoria_id) {
        let categorias = this.USER.DATABASE.objetos_categorias;
        console.log(categorias);
        let categoria = categorias.find(data => data.id == categoria_id);
        return categoria ? categoria.nombre : null; // Maneja el caso donde no se encuentra la categoría
    }


    async crearParqueaderoDirecto() {

       

        this.log('Iniciando Proceso de creaccion de parqueadero Directo.' )
        
        const infoTipoDeDocumento =  this.getTipoDocumentoId();

        if(infoTipoDeDocumento){
            this.DOCUMENTO  =   generarDocumentoFantasma(infoTipoDeDocumento ,  this.USER );
            this.DOCUMENTO.KEYDOCUMENT  = this.KEYDOCUMENT; 
        
            let item  = await this.extraerItemParqueadero();

            if(item){
                this.insertarItemParqueadero(item);
                return true;
            }else{
               
                alert("No encontramos Item relacionado con el parqueadero")
            }
        }else{
            alert("No hay tipo de documentos")
        }

        return false;
    }


    loadDocumentoLocalStore(){
        
        this.DOCUMENTO  = getLocalstore(this.KEY_LOCAL_STORE);;

        console.log("El documento de Localsoter es el sgieuinte")

        console.log(this.DOCUMENTO)
    }














    /***
     * 
     * 
     *  Salida del párqueadero
     * 
     */
    rederizarSalida(){
        console.log("Iiniciando proceso de renderizacion de salida")
        this.USER = fetchUSer();
        this.loadDocumentoLocalStore();
        this.DOCUMENTO.patio_salida_fecha =  __app.getFecha();
        this.instanciarDocumentoGlobal();



        this.CobrarParqueadero();
        this.DOCUMENTO  = this.LOGICADOCUMENTO.DOCUMENT;
    }
    
    CobrarParqueadero(){
        
        if(this.LOGICADOCUMENTO.DOCUMENT?.OBJETO?.objetos_categoria_id){


            
            let precio = this.calcularPrecio(this.LOGICADOCUMENTO.DOCUMENT.OBJETO.objetos_categoria_id);
            
            
            this.LOGICADOCUMENTO.DOCUMENT.patio_tiempo = this.RESPUESTA_ALGORITMO_PARQUEADERO.TIEMPO.textoTiempo;



           if(precio > 0 ){
                let valor = precio
                let accion = 'cambiar_precio_documento';
                let indexx  = 0;
        
            this.handleBlur({indexx,valor, accion});
           }
        }else{

            this.log("No hay Objeto en el documento")
        }
        

        
        //
    }





    handleBlur = ({indexx,valor, accion}) => {
   
        this.LOGICADOCUMENTO.despachador( accion , {
            indexx,valor        
        })   
    }


    calcularPrecio(categoria_id){
        
       
        return this.extraerTarifabyCategoria(categoria_id);

    
    }


    extraerTarifabyCategoria(categoria_id){

       

        if(this.USER.DATABASE.tarifa_parqueaderos){
            this.log("El Array de tarifas due encontrado");    
            const ListTarifa = this.USER.DATABASE.tarifa_parqueaderos;
            const tarifa = ListTarifa.find(data => data.objeto_categoria_id == categoria_id )
            
            this.log(tarifa)
            if(tarifa?.algoritmos){
                const respuesta = Renderizar({
                    fecha1 :   this.formatearFecha(this.DOCUMENTO.patio_entrada_fecha), 
                    fecha2:    this.formatearFecha(this.DOCUMENTO.patio_salida_fecha), 
                    algoritmo : tarifa.algoritmos,
                    tarifasbd : tarifa
                })
                console.log(respuesta);
                if(respuesta.TOTALES.VALOR_A_PAGAR){
                    this.RESPUESTA_ALGORITMO_PARQUEADERO = respuesta; 
                    return respuesta.TOTALES.VALOR_A_PAGAR;
                }else{
                    alert("No se pudo cobrar el parqueadero")
                    return 0;
                }
            }else{
                alert("no hay Algoritmo  para la Categoria")
            }
        }else{

            this.log("No tenemos Tarifas");
            return 0;
        }
    }

    formatearFecha(fecha_sin_formato) {
        this.log(fecha_sin_formato);
    
        const fecha = new Date(fecha_sin_formato);
    
        // Formatear manualmente la fecha respetando la zona horaria local
        const año = fecha.getFullYear();
        const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
        const día = String(fecha.getDate()).padStart(2, '0');
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
    
        const fechaISO = `${año}-${mes}-${día}T${horas}:${minutos}`;
        
        this.log(fechaISO);
        return fechaISO;
    }


    getTipoDocumentoId = () => {
        console.log(this.USER)
        const Tipodocumentos  =  this.USER.DATABASE.tipo_documento;

        if(Tipodocumentos){
            return Tipodocumentos.find((data) => data.id ==  this.TIPO_DOCUMENTO_ID );
        }else{
            console.log(this.USER)
            alert("Error sin documento")
            return null;
        }
       
    }





    setDispatch(dispatch){

        this.DISPATCH = dispatch;
    }


    instanciarDocumentoGlobal(){
        this.LOGICADOCUMENTO  =  new DocumentL(this.DOCUMENTO , this.KEY_LOCAL_STORE , this.DISPATCH  , this.USER );
    }

    insertarItemParqueadero(item){
        
        
        const datos = 
        {   
            item: item, 
            options: { 
                cantidad: 1,  
                PRECIO : 0
            } 
        } ;

        
        this.instanciarDocumentoGlobal();
        
        console.log(datos);

        this.LOGICADOCUMENTO.despachador('insertarItem', datos);

        console.log(this.LOGICADOCUMENTO.DOCUMENT)

        let copy = this.LOGICADOCUMENTO.clonarObjeto(this.LOGICADOCUMENTO.DOCUMENT);



        let newform = {
            "nombreCajero": this.USER.USER.nombre
        };

        let FORM = { ...copy.FORM, ...newform };
   
      
        
        copy = {...copy ,
            FORM: FORM,
            estado_patio : 1 ,
            placa : this.PLACA, 
            OBJETO : this.VEHICULO,
            patio_entrada_fecha: __app.getFecha(),
            patio_salida_fecha : null,
            patio_tiempo : null // Debe calcular los minutos que el vehiculo duro en el patio.
        }



        console.log(copy)
        guardarDocumentoLocal(copy);
        this.DOCUMENTO  = copy;
    }

    async extraerItemParqueadero(){
        this.log('extraerItemParqueadero' )
        var   data = await  ExtraerTablabyIds("productos" , [ this.CODIGO_SERVICIO_PARQUADERO]  , 'id' );
        this.log('El item encontrado es el siguiente' )
        if(data.length > 0 ){
            return data[0];
        }else{
            this.log('No tenemos producto relacionado con parqueadero. ' )
            return null;
        }
    }

    log (data){

        console.log(data);
    }
}