class Tiempos {



    constructor() {




        /***
         * 
         *  Minutos gabela es la cantidad de minutos que debe de pasar antes de convertir el minuto actual e suna hora
         *  Ejemplo:
         *   
         *    En un parqueadero donde la tenemos 1:30 minutos queremos que automaticamente quede en 2 horas para el coboro
         */
        this.MinutosGabela = 30




        this.HORA_DE_INICIO_DE_LA_NOCHE = 18; /// Valor Recomentado es 18 Horas



        this.HORA_DE_INICIO_DEL_DIA = 6;  /// Valor recomentado es 6 horas





        // Fechas
        /**
         * 
         * 
         */
        this.TIEMPO = {

            // Contiene el numero de dias Completos
            diasCompletos: 0,

    

            //Horas sobrantes hablando de 24 horas.
            horas_sobrante_by24H: 0,

             //Horas sobrantes hablando de 12 horas.
            horas_sobrante_by12H: 0,



            //Minutos sobrantes
            minutos_sobrantes: 0,



            //Minutos sobrantes
            minutos_a_cobrar : 0,



            //Total horas.
            totalHoras: 0,


            //Total de Horas Nocturnas
            horas_sobrante_nocturnas : 0,


            // Total de horas diurnas
            horas_sobrante_diurnas : 0,

            

            //Numero de dias de 12 horas.
            // Para que el algoritmo pueda registrar aqui debe cumplir exactamente 12 horas en el
            dias_de_12 : 0,

            
            //noches completas consumdidas de 12 horas
            noches_de_12: 0,


            // Contiene los dias y noches trasncurridos
            dias_y_noches_12:0,


            //El tiempo registrado
            textoTiempo : ''

        };



        //** Seguramente la variable mas importante */
        this.TIEMPO_EN_BLOQUE = [];





        this.nocturnas = [18, 5];
        this.diurnas = [6, 17];

        this.log = []; 
    }



    /**
     * 
     * Renderizacion del sistema.
     */
    index(fecha1, fecha2) {
        
        this.addLog("Iniciando Index ")


        this.addLog("Fecha1." + fecha1 )

        this.addLog("Fecha2." + fecha2 )


        this.fecha1 = new Date(fecha1);
        
        this.fecha2 = new Date(fecha2);
        
        const diff = Math.abs(this.fecha2 - this.fecha1);
        
        const intervalo = {
            days: Math.floor(diff / (1000 * 60 * 60 * 24)),
            h: Math.floor((diff / (1000 * 60 * 60)) % 24),
            i: Math.floor((diff / (1000 * 60)) % 60)
        };

        
        this.TIEMPO.diasCompletos = intervalo.days;
        this.TIEMPO.horas_sobrante_by24H       = intervalo.h;
        this.TIEMPO.minutos_sobrantes    = intervalo.i;
        this.TIEMPO.totalHoras = this.calcularTiempoTotal();
        this.TIEMPO.dias_y_noches_12 = String(  this.TIEMPO.totalHoras  / 12).split(".")[0];
   


  

        let cantidad = this.TIEMPO.dias_y_noches_12 * 12;
        this.TIEMPO.horas_sobrante_by12H  = this.TIEMPO.totalHoras - cantidad;  
        if(this.TIEMPO.horas_sobrante_by12H < 0 ){
            this.TIEMPO.horas_sobrante_by12H = 0;
        }

    
        this.ROMPECABEZAS();

        this.textoTiempo();

      
    }





    calcularTiempoTotal() {
        let total = this.TIEMPO.diasCompletos * 24;
        total += this.TIEMPO.horas_sobrante_by24H;
        return total;
    }







    ROMPECABEZAS() {
       
        let horaIngreso = this.fecha1.getHours();
        
        this.addLog("Hora de ingreso : " + horaIngreso);
        

        /*
        /* Esta funcion lo que hace es seprar las horas por cada DIA O NOCHE concurrido 
        /*
        /*  Ejemplo_
           
            Suponiendo que un carro entro a las 6 AM y salio al otro dia a las 9AM  este seria el resultado

            7 8 9 10 11 12 13 14 15 16 17 18 -> Estas serian las horas de dia
            19 20 21 22 23 24 1 2 3 4 5 6 -> Horas nocturna
            7 8 9 -> Horas diurnas    

        */
        let horas = [];
        let y = 1;
        let j = 0;
        let key = 0;

        for (let i = 0; i < this.TIEMPO.totalHoras; i++) {
    
            let hora = horaIngreso + y;

            if (hora === this.HORA_DE_INICIO_DE_LA_NOCHE || hora === this.HORA_DE_INICIO_DEL_DIA) {
               
                key++;
                j = 0;
            }



            if (hora === 24) {
                horaIngreso = 0;
                y = 0;
            }

            if (!horas[key]) horas[key] = [];
            horas[key][j] = hora;
            y++;
            j++;
        }

        this.TIEMPO_EN_BLOQUE = horas;
      



        this.recorrerResultados();
   
    }

    recorrerResultados() {
    
        this.calcularTiemposdeHoras();   

    }



    /**
     
    *  Esta funcion verifica el boque, y nos dice cuantas noche y cuantos dias fue el parqueadero
    *   /*
        /* Esta funcion lo que hace es seprar las horas por cada DIA O NOCHE concurrido 
        /*
        /*  Ejemplo_
        
            Suponiendo que un carro entro a las 6 AM y salio al otro dia a las 9AM  este seria el resultado

            7 8 9 10 11 12 13 14 15 16 17 18 -> Estas serian las horas de dia
            19 20 21 22 23 24 1 2 3 4 5 6 -> Horas nocturna
            7 8 9 -> Horas diurnas    

    */

    calcularTiemposdeHoras() {

    
        
        let horas =  this.TIEMPO_EN_BLOQUE;
        
        let horasDiurnas = 0;
        let horasNocturnas = 0;
        
        horas.forEach(dia => {
            dia.forEach(hora => {
                if (hora >= this.HORA_DE_INICIO_DEL_DIA && hora < this.HORA_DE_INICIO_DE_LA_NOCHE) {
                    horasDiurnas++;
                } else {
                    horasNocturnas++;
                }
            });
        });
        
        // Dividir horas entre 12 para contar días/noches completos y calcular horas sobrantes
        this.TIEMPO.dias_de_12 = Math.floor(horasDiurnas / 12);
        this.TIEMPO.horas_sobrante_diurnas   = horasDiurnas % 12;
        



        this.TIEMPO.noches_de_12 = Math.floor(horasNocturnas / 12);
        this.TIEMPO.horas_sobrante_nocturnas = horasNocturnas % 12;
        

     
    }


    
    textoTiempo (){

        // Construimos el texto de manera dinámica.
        let textoTiempo = '';

        if (this.TIEMPO.dias_y_noches_12 > 0) {
            textoTiempo += `${this.TIEMPO.dias_y_noches_12} dia_noche ${this.TIEMPO.dias_y_noches_12 > 1 ? 's' : ''} `;
        }
        
 

        if (this.TIEMPO.horas_sobrante_by12H > 0) {
            textoTiempo += `${this.TIEMPO.horas_sobrante_by12H} hora${this.TIEMPO.horas_sobrante_by12H > 1 ? 's' : ''} `;
        }

        if (this.TIEMPO.minutos_sobrantes > 0) {
            textoTiempo += `${this.TIEMPO.minutos_sobrantes} minuto${this.TIEMPO.minutos_sobrantes > 1 ? 's' : ''} `;
        }

        // Quitamos el último espacio en blanco si es necesario
        textoTiempo = textoTiempo.trim();

        // Si no hay tiempo, ponemos una cadena vacía o algo predeterminado
        if (textoTiempo === '') {
            textoTiempo = 'Sin tiempo registrado';
        }

        this.TIEMPO.textoTiempo = textoTiempo;
    }


    addLog(log){
        this.log = [...this.log ,log ];
    }
}

export default Tiempos;




















