import React, { useEffect, useState } from 'react';
import { Parqueadero } from './Core/Parqueadero';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import ListaCategorias from '../Categorias/ListaCategorias';
import Patio from '../Patio/Patio';
import { __app, __fecha } from '../../../utils/myApp';
import Salida from '../Salida/Salida';
import { DocumentProvider , documentReducer , initialDocument  } from '../../Document/context/ContextDocument';
import ReciboPatio from '../Patio/ReciboPatio';
import BarraEstado from '../../Document/BarraEstado';
import '../Utilidades/estilos.css'
import { useStateValue } from '../../../context/StateProvider';
import TurnoIndex from '../../Turnos/TurnoIndex';
import TurnoComponente from '../../Turnos/TurnoDetalleFinalizar';
import { enviarPeticion } from '../../../Core/ActionDatabase';
const ClassParqueadero = new Parqueadero;

const Pages = () => {

    
    const [ {user}  , dispatch ] = useStateValue();

    const [turnocerrado , setTurnocerrado] = useState(null); 

    const vehiculoInicial = {
        identificacion : '',
        nombre : '',
        objetos_categoria_id  : '',
    }

    const [placa, setPlaca] = useState('');

    /***
     * 
     * Esta Variable es usada para recargar el patio Automaticamente, 
     * 
     */
    const [reloadpatio, setReloadpatio ] = useState(__app.numeroFecha());

    /***** 
     *  -1 primer Paso
     *  0 Vehiculo nuevo
     *  1 Vehiculo Registrado
     */
    const [pasoActual , setPasoActual] = useState(-1);

    /**** El cache del documento que vamos a imprimir. */
    const [documentoImprimir , SetdocumentoImprimir ] = useState();  

    const handleKeyPress  = async (event) => {
        if (event.key === 'Enter') {
            let respuesta  = await ClassParqueadero.renderizarPlaca(placa);
            setPasoActual(respuesta);
            if(respuesta == 1){
                crearParqueaderoDirecto();
            }
        }
    };

    const extraerCategoria  = (categoria_id, descripcion) => {
        let infoVehiculo = {
            identificacion : ClassParqueadero.formatearPlaca(placa),
            nombre : descripcion,
            objetos_categoria_id  : categoria_id,
            id: null,
        };
        ClassParqueadero.setVehiculo(infoVehiculo);
        ClassParqueadero.registrarVehiculo(infoVehiculo);
        crearParqueaderoDirecto();
    }

    const crearParqueaderoDirecto = async () => {
        let respuesta = await ClassParqueadero.crearParqueaderoDirecto();
        if(respuesta){
            imprimirEntrada(ClassParqueadero.DOCUMENTO);
        }
        resetearPanel();
        return 
    }

    const imprimirEntrada = (document) => {
        console.log(document);
        SetdocumentoImprimir(document);
        setTimeout(() => {
            SetdocumentoImprimir(null);
        }, 1000);
    }

    const resetearPanel = () => {
        setPasoActual(-1);
        setPlaca('');
        setTimeout(() => {
            setReloadpatio(__app.numeroFecha());
        } , 200);
    }

    const cerrarTurnoLocal = () => {
        console.log(user);

        const { TURNO , ...Newuser } = user;

     
        
        dispatch ({
            type : 'SET_USER',
            user: Newuser,
        })   

        
        localStorage.setItem('user', JSON.stringify(Newuser)); 
    }



    const consultarEstadoTurno =  async () => {
        console.log(user)
        if (!user.TURNO || !user.TURNO.id){
            console.log(false);
            setTurnocerrado("closed");
            return false;
        }
        
        const respuesta = await enviarPeticion("turnos/consultar_turno_estado",user.TURNO.id , {          
        } , true , 5000);

    
        if  (!respuesta.server_error){
            
            console.log(respuesta)
            
            ///Turno cerrado
            if(respuesta.data.resultado ){
             
                setTurnocerrado("closed");
                cerrarTurnoLocal();
            }else{
                
                setTurnocerrado("open");
            }
        }else{
            setTurnocerrado("open");
        }
        
    }

    const cerrarSession = () => {     
        localStorage.removeItem('user');

        dispatch( {
            user : null,
            type : 'SET_USER' 
        })
         window.location.href ='/';
    }

    useEffect(() => {
        consultarEstadoTurno()
    },[] )


       
    if(user.FINALIZAR_TURNO){
        
        return (<TurnoComponente user={user}  />)
    }



    if(turnocerrado == "closed") {
        
        return( 
            <div>
        
                <TurnoIndex estadoTurno={setTurnocerrado} user={user}  cerrarSession={cerrarSession} />
               

            </div>  );  
    }




    if(!user.TURNO.id){
        return ("Debe abrir un turno para Operar")
    }


    return (
        <div className="container">
            <DocumentProvider initialState={initialDocument} reducer={documentReducer}>

                <h1 className="text-center mb-4">Modulo de parqueadero</h1>

                {pasoActual >= 0 && (
                    <button onClick={resetearPanel} className="btn btn-danger mb-4">Reiniciar</button>
                )}

                {pasoActual == -1 && (
                    <div className="d-flex justify-content-center mb-4">
                        <input
                            className="placa-input text-center"
                            placeholder="Ingrese la placa"
                            value={placa}
                            onChange={(e) => setPlaca(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                         {placa.length >= 2 && 
                           
                            <div>   <p className="text-info mt-2 d-block"><br/>Presiona Enter</p>   </div>
                        }
                    </div>
                )}

                {pasoActual == 0 && <ListaCategorias extraerCategoria={extraerCategoria} />}

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            {pasoActual == 2 && <Salida resetearPanel={resetearPanel} />}
                        </div>
                    </div>
                </div>

                {pasoActual == 3 && <p className="text-danger text-center">La placa es invalida: {placa}</p>}

                {pasoActual == -1 && <Patio reloadPatio={reloadpatio} imprimirEntrada={imprimirEntrada} resetearPanel={resetearPanel} />}

                {documentoImprimir && <ReciboPatio documento={documentoImprimir} />}

                <BarraEstado />
            </DocumentProvider>
        </div>
    );
};

export default Pages;
